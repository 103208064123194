import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import config from '../config/config';
import AdsComponent from './AdsenseComponent';
import { useNavigate } from 'react-router-dom';
import PdfModal from '../common/PdfModal';



function BooksContent() {
    const { id } = useParams();
    const navigate = useNavigate();
    
    const [showModal, setShowModal] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');


    const { API_BASE_URL } = config;
    const [bookDetails, setBookDetails] = useState(null);

    useEffect(() => {
        const fetchBookDetails = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/Home/Getbooks`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: id,
                    }),
                });

                const data = await response.json();
                setBookDetails(data);
            } catch (error) {
                console.error('Error fetching book details:', error);
            }
        };

        fetchBookDetails();
    }, [API_BASE_URL, id]);

    if (!bookDetails) {
        return <div>Book details not found</div>;
    }

    const handleAddToCart = (book) => {
        const storedCart = localStorage.getItem('cart');
        const cart = storedCart ? JSON.parse(storedCart) : [];

        const existingItemIndex = cart.findIndex((item) => item.id === book.id);

        if (existingItemIndex !== -1) {
            cart[existingItemIndex].quantity += 1;
        } else {
            cart.push({
                id: book.id,
                name: book.booknameinenglish,
                price: book.discountedprice,
                quantity: 1,
                image: book.image,
            });
        }

        localStorage.setItem('cart', JSON.stringify(cart));

        console.log(`Added ${book.booknameinenglish} to cart`);
    };

    const handleViewPdfClick = (bookpdflink) => {

        setPdfUrl(bookpdflink);
        setShowModal(true);
        // sessionStorage.setItem('pdflink',bookpdflink)
        if(bookpdflink.includes('drive.google.com')){

            // console.log("Pdflink",bookpdflink);
            console.log(bookpdflink.includes('drive.google.com'));
            return  window.open(bookpdflink, '_blank');
      
          }
          else{
            //  navigate('/books/flip');


          }
      
        // Show the ad
        // setShowAd(true);
        // // Hide the ad after 10 seconds
        // setTimeout(() => {
        //     setShowAd(false);

        //     // navigate('books/flip');
        //     // Redirect to PDF link
        //     // window.open(bookData.pdflink, '_blank');
        // }, 10000);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div className='max-w-5xl mx-auto mb-4'>
            {bookDetails.map((bookDetails) => (
                <section class="py-12 sm:py-16" key={bookDetails.id}>
                    <div class="container mx-auto px-4">
                        <div class="lg:col-gap-12 xl:col-gap-16 mt-8 grid grid-cols-1 gap-12 lg:mt-12 lg:grid-cols-5 lg:gap-16">
                            <div class="lg:col-span-3 lg:row-end-1">
                                <div class="lg:flex lg:items-start">
                                    <div class="lg:order-2 lg:ml-5">
                                        <div class="max-w-xl overflow-hidden ">
                                            <img class="h-full w-full max-w-full object-cover rounded-lg" src={bookDetails.image} alt="Book-Im-Id" />
                                            <p className='' rel="noopener noreferrer">
                                                {/* <button    onClick={ ()=>handleViewPdfClick(bookDetails.pdflink)} className='w-full p-2 bg-blue-600 text-white mt-4 rounded-md'>Read Book (Free)</button> */}
                                                <a
                          href={bookDetails.pdflink}
                          download
                          rel="noopener noreferrer"
                          target="_blank"
                          // onClick={(e) => {
                          //   e.preventDefault(); // Prevent default navigation if you also want to handle the click event
                          //   handleViewPdfClick(bookDetails.pdflink);
                          // }}
                        >
                          <button class="w-full p-2 bg-blue-600 text-white mt-4 rounded-md">
                            Download For Free
                          </button>
                        </a>
                                            </p>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <div class="lg:col-span-2 lg:row-span-2 lg:row-end-2">
                                <h1 class="sm: text-2xl font-bold text-orange-500 underline underline-offset-2 sm:text-3xl px-2 py-2 w-fit">{bookDetails.booknameinenglish}</h1>

                                <h2 class="mt-8 text-sm font-semibold text-gray-900">Writer : {bookDetails.writer}</h2>

                                <div class="lg:col-span-3 ">
                                    {/* <div class="border-b border-gray-300">
                                        <nav class="flex gap-4">
                                            <p class="border-b-2 border-gray-900 py-4 text-sm font-medium text-gray-900 "> Description </p>


                                        </nav>
                                    </div> */}

                                    <div class="mt-4 flow-root sm:mt-5 bg-gray-100 p-4 rounded-md">
                                        <h1 class="text-xl font-bold">{bookDetails.booknameinhindi}</h1>
                                        <p class="mt-4"><span className='font-light'>About the book</span> : {bookDetails.description}</p>
                                        {bookDetails.illustrator !== "" && (
                                            <p class="mt-2">
                                                <span className=' font-light'>Illustrator</span> : {bookDetails.illustrator}
                                            </p>
                                        )}
                                        <p class="mt-2"><span className='font-light '>Age-Group</span> : {bookDetails.agegroup}</p>

                                        {bookDetails.noofpages !== "" && (
                                            <p class="mt-2">
                                                <span className=' font-light'>Pages</span> : {bookDetails.noofpages}
                                            </p>
                                        )}
                                    </div>
                                </div>

                                <div class="mt-10 flex flex-col items-center justify-between space-y-4 border-t border-b py-4 sm:flex-row sm:space-y-0">
                                    <div class="flex items-end">
                                        <h1 class="text-xl font-medium line-through">Rs.{bookDetails.price}</h1>
                                        <h1 class="text-3xl font-bold">{' '}{bookDetails.discountedprice}</h1>
                                        <span class="text-base font-light">only</span>
                                    </div>

                                    <button
                                        onClick={() => handleAddToCart(bookDetails)}
                                        type="button" class="inline-flex items-center justify-center rounded-md border-2 border-transparent bg-gray-900 bg-none px-8 py-3 text-center text-base font-bold text-white transition-all duration-200 ease-in-out focus:shadow hover:bg-gray-800">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="shrink-0 mr-3 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                        </svg>
                                        Add to cart
                                    </button>
                                </div>

                                <ul class="mt-8 space-y-2">
                                    <li class="flex items-center text-left text-sm font-medium text-gray-600">
                                        <svg class="mr-2 block h-5 w-5 align-middle text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" class=""></path>
                                        </svg>
                                        Shipping worldwide
                                    </li>

                                    <li class="flex items-center text-left text-sm font-medium text-gray-600">
                                        <svg class="mr-2 block h-5 w-5 align-middle text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" class=""></path>
                                        </svg>
                                        Cancel Anytime
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            ))}

            {/* <AdsComponent /> */}
                        {showModal &&  pdfUrl &&  <PdfModal value={showModal} pdf_link={pdfUrl}  onClose={handleCloseModal}/>}
        </div>
    )
}

export default BooksContent;





