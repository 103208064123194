/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import config from '../config/config';
import { useNavigate } from 'react-router-dom';
import PdfModal from '../common/PdfModal';
import {  useDispatch } from 'react-redux';
import {  addToCart } from '../ReduxSlice/BookSlice';



function Hindi() {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const { API_BASE_URL } = config;

    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const itemsPerPage = 8;
    const [hindiBooks, setHindiBooks] = useState([]);
    const [showAnimationText, setShowAnimationText] = useState({});
    const [selectedAgeGroup, setSelectedAgeGroup] = useState('Adults');
    const [showModal, setShowModal] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/Home/Getbooks`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        ageGroup: selectedAgeGroup,
                        language: 'Hindi',
                        searchTerm: searchTerm,
                    }),
                });

                const data = await response.json();

                const filteredBooks = data
                    .filter((book) =>
                        (book.language === 'Hindi' || book.language === 'Hindi-English') &&
                        (book.booktype === 'general books' || book.booktype === 'books made by children')
                    )
                    .filter((book) =>
                        (selectedAgeGroup === 'Adults' && book.agegroup === 'Adults') ||
                        (selectedAgeGroup !== 'Adults' && (book.agegroup === selectedAgeGroup))
                    )
                    .filter((book) =>
                        book.booknameinenglish.toLowerCase().includes(searchTerm.toLowerCase())
                    );


                setHindiBooks(filteredBooks);
                setCurrentPage(1);
            } catch (error) {
                console.error('Error fetching data:', error);

            }
        };

        fetchData();
    }, [API_BASE_URL, searchTerm, selectedAgeGroup]);

    const handleViewPdfClick = (bookpdflink) => {
        setPdfUrl(bookpdflink);
        setShowModal(true);
       

        // sessionStorage.setItem('pdflink',bookpdflink)
        if(bookpdflink.includes('drive.google.com')){

            // console.log("Pdflink",bookpdflink);
            // console.log(bookpdflink.includes('drive.google.com'));
            return  window.open(bookpdflink, '_blank');
      
          }
          else{
            //  navigate('/books/flip');


          }
      
        // Show the ad
        // setShowAd(true);
        // // Hide the ad after 10 seconds
        // setTimeout(() => {
        //     setShowAd(false);

        //     // navigate('/books/flip');
        //     // Redirect to PDF link
        //     // window.open(bookData.pdflink, '_blank');
        // }, 10000);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };




    const totalPages = Math.ceil(hindiBooks.length / itemsPerPage);

    const handleAddToCart = (book) => {
        // const storedCart = localStorage.getItem('cart');
        // const cart = storedCart ? JSON.parse(storedCart) : [];

        // const existingItemIndex = cart.findIndex((item) => item.id === book.id);

        // if (existingItemIndex !== -1) {
        //     cart[existingItemIndex].quantity += 1;
        // } else {
        //     cart.push({
        //         id: book.id,
        //         name: book.booknameinenglish,
        //         price: book.discountedprice,
        //         quantity: 1,
        //         image: book.image,
        //     });
        // }
        dispatch(addToCart(book));
        setShowAnimationText((prev) => ({
            ...prev,
            [book.id]: true,
        }));

        setTimeout(() => {
            setShowAnimationText((prev) => ({
                ...prev,
                [book.id]: false,
            }));
        }, 1000);

        // localStorage.setItem('cart', JSON.stringify(cart));

        // console.log(`Added ${book.booknameinenglish} to cart`);
    };

    const scrollDownToSection = () => {
        const section = document.getElementById("hindi-list");
        section?.scrollIntoView({ behavior: "smooth" });
    };
    const BookDetails = (book) =>{
        navigate(`/book/${book.id}`)
    }


    return (

        <section className='mt-8 mb-8'>
            <form action="/search" class="w-full px-4 max-w-4xl justify-center mx-auto">
                <div id='hindi-list' class="relative border border-blue-600/40 rounded-full">
                    <input type="text"
                        id="search"
                        placeholder="Search books by name"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full h-12 p-4 rounded-full" />
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="text-teal-400 h-5 w-5 absolute top-3.5 right-3 fill-current">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                    </svg>
                </div>
            </form>

            <div className="flex items-center md:gap-5 lg:gap-5 xl:gap-5 gap-1 mt-4 mx-auto justify-center px-1">
                <button
                    onClick={() => setSelectedAgeGroup('2.5-8 years')}
                    className={`px-4 py-1 mr-2 font-semibold ${selectedAgeGroup === '2.5-8 years' ? 'bg-blue-700 text-white' : 'bg-blue-500 text-white'
                        } rounded`}
                >
                    2.5 - 8 years
                </button>
                <button
                    onClick={() => setSelectedAgeGroup('8-14 years')}
                    className={`px-4 py-1 mr-2 font-semibold ${selectedAgeGroup === '8-14 years' ? 'bg-blue-700 text-white' : 'bg-blue-500 text-white'
                        } rounded`}
                >
                    8 - 14 years
                </button>
                <button
                    onClick={() => setSelectedAgeGroup('Adults')}
                    className={`px-4 py-1 font-semibold ${selectedAgeGroup === 'Adults' ? 'bg-blue-700 text-white' : 'bg-blue-500 text-white'
                        } rounded`}
                >
                    Adults
                </button>
            </div>


            <div className='max-w-7xl mx-auto py-5'><p className='text-lg font-semibold bg-yellow-400 py-2 px-3 w-fit mb-2'><h2>Hindi Book List</h2></p> <hr className='border-b-2 border-gray-200' /></div>

            <div className="text-left md:flex-row max-w-full md:px-16 lg:px-16 justify-center xl:px-20 px-[2px] mx-auto md:mt-5 mt-10 items-center z-0">

                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 md:px-5 lg:px-12 xl:px-12  px-0 gap-8 md:space-y-0 lg:space-y-0 xl:space-y-0 space-y-2 pb-2 md:gap-y-8 lg:gap-y-8 xl:gap-y-8 gap-y-5">
                    {hindiBooks.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((book) => (

                        <div
                            key={book.id}
                            className="relative flex flex-col text-gray-800 bg-white md:w-64 lg:w-72 xl:w-72 w-full border border-gray-200 p-1 rounded-md space-y-3 hover:scale-105 hover:transition hover:ease-out cursor-pointer">
                            <div className="relative h-full text-white overflow-hidden shadow-lg bg-blue-gray-500 bg-clip-border shadow-blue-gray-500/40 flex items-center mx-auto bg-white">
                                <div  onClick={()=>BookDetails(book)}>
                                    <img
                                        src={book.image}
                                        alt={book.booknameinenglish}
                                        layout="fill"
                                        objectFit="cover"
                                        className="bg-blue-200 h-54 mx-auto"
                                    />
                                </div>
                            </div>

                            <div  onClick={()=>BookDetails(book)} className='flex items-center justify-between px-1 cursor-pointer'>
                                <h5 className="block font-sans md:text-xl lg:text-xl xl:text-xl text-xl font-bold leading-snug tracking-normal text-blue-gray-900">
                                    {book.booknameinenglish}
                                </h5>



                            </div>
                            <Link to={`/book/${book.id}`} onClick={(e) => e.stopPropagation()} className='items-center flex text-xs gap-1 px-2 underline underline-offset-2 hover:bg-yellow-300 hover:rounded-full hover:px-3 hover:transition hover:delay-200 hover:py-1 w-fit'>
                                Details
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-4 h-4 ">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                            </Link>

                           {/* <p onClick={() => handleViewPdfClick(book.pdflink)} rel="noopener noreferrer" className='px-3 font-bold text-gray-600 py-0.5 bg-yellow-400 w-fit rounded-md text-xs'>
                                View PDF - Free
                            </p> */}
                            <p>
                            <a
                      href={book.pdflink}
                      download
                      rel="noopener noreferrer"
                      target="_blank"
                      //   onClick={(e) => {
                      //     e.preventDefault(); // Prevent default navigation if you also want to handle the click event
                      //     handleViewPdfClick(book.pdflink);
                      //   }}
                    >
                      <button className="px-3 font-bold text-gray-600 py-0.5 bg-yellow-400 w-fit rounded-md text-xs">
                        Download For Free
                      </button>
                      
                    </a>
                            </p>

                            <p   onClick={()=>BookDetails(book)} className="block text-sm px-2 font-normal leading-5">
                                {book.description}
                            </p>

                            <div class="flex items-center justify-between px-2 pb-1">
                                <p  onClick={()=>BookDetails(book)}> <span className='text-xl font-semibold text-slate-900'>Rs.</span>
                                    <span class="text-sm text-slate-900 line-through">{book.price}</span>
                                    <span class="text-2xl font-bold text-slate-900">{book.discountedprice}{' '}</span>
                                </p>
                                <div class="flex items-center">
                                    <div class="relative py-2">
                                        <div class="t-0 absolute left-16 -mt-2 bg-green-400 rounded-full">

                                            {showAnimationText[book.id] && (
                                                <p className='flex h-2 w-2  animate-ping items-center justify-center rounded-full bg-green-500 p-3 text-xs text-white'>
                                                    +1
                                                </p>
                                            )}
                                        </div>
                                        <button onClick={() => handleAddToCart(book)} class="flex items-center rounded-full bg-[#e4002b] px-3 py-1 text-center text-sm font-medium text-gray-200 hover:bg-yellow-400 focus:outline-none focus:ring-4 focus:ring-blue-300" >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="mr-2 h-4 w-4">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                            </svg>
                                            Add</button>
                                    </div>
                                </div>
                            </div>


                        </div>
                    ))}

                </div>
            </div>

            <div className="flex justify-center mt-8">
                <button
                    onClick={() => {
                        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
                        scrollDownToSection();
                    }}
                    disabled={currentPage === 1}
                    className="px-3 py-2 mr-2 font-semibold text-gray-700 bg-gray-200 rounded"
                >
                    Previous
                </button>
                <span className="px-3 py-2 font-semibold text-gray-700">
                    Page {currentPage} of {totalPages}
                </span>
                <button
                    onClick={() => {
                        setCurrentPage((prevPage) => prevPage + 1);
                        scrollDownToSection();
                    }}
                    disabled={currentPage * itemsPerPage >= hindiBooks.length}
                    className="px-3 py-2 ml-2 font-semibold text-white bg-blue-500 rounded"
                >
                    Next
                </button>
            </div>
            {showModal &&  pdfUrl &&  <PdfModal value={showModal} pdf_link={pdfUrl}  onClose={handleCloseModal}/>}

        </section>
    );
}

export default Hindi;
